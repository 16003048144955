<template>
  <div class="login">
     <div class="login__title">
       Введите новый пароль
     </div>
    <div class="login__form">
      <el-form
        :model="form"
        :rules="rules"
        ref="loginForm"
      >
        <el-form-item
          label="Пароль"
          prop="password"
          :class="{ focused: isActiveInput === 'password' || form.password }"
          :show-message="false"
        >
          <el-input
            v-model="form.password"
            type="password"
            @focus="isActiveInput = 'password'"
            @blur="isActiveInput = ''"
          />
        </el-form-item>
        <el-form-item
          label="Повторите пароль"
          prop="password_confirmation"
          :class="{ focused: isActiveInput === 'password_confirmation' || form.password_confirmation }"
          :show-message="false"
        >
          <el-input
            v-model="form.password_confirmation"
            type="password"
            @focus="isActiveInput = 'password_confirmation'"
            @blur="isActiveInput = ''"
          />
        </el-form-item>
      </el-form>
      <div class="login__form-button">
        <ButtonElement text="Отправить" @click="resetPass('loginForm')"/>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from '../components/elements/ButtonElement'

export default {
  name: 'ForgotPass',
  components: {
    ButtonElement,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Введите пароль'));
      } else if(value.length < 6){
          callback(new Error('Пароль должен быть больше 6 символов'));
      }
      else {
        if (this.form.password_confirmation !== '') {
          this.$refs['loginForm'].validateField('checkPass');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Повтрите пароль'));
      } else if (value !== this.form.password) {
        callback(new Error('Пароли не совпадают'));
      } else {
        callback();
      }
    };
    return {
      isActiveInput: '',
      token: '',
      email: '',
      form: {
        password: '',
        password_confirmation: '',
      },
      rules: {
        password: [{ validator: validatePass, trigger: 'blur' }],
        password_confirmation: [{ validator: validatePass2, trigger: 'blur' }],
      },
    }
  },
  mounted() {
    this.token = this.$route.query.token
    this.email = this.$route.query.email
  },
  methods: {
    resetPass (form) {
      this.$refs[form].validate(valid => {
        if(valid) {
          const data = {
            token: this.token,
            email: this.email,
            password: this.form.password,
            password_confirmation: this.form.password_confirmation,
          }
          this.$store.dispatch('Auth/resetPass', data)
          .then(resp => {
            this.$router.push('/login')
            console.log(resp)
          })
          .catch(err => {
            console.log(err.response.data.errors)
          })
        }
      });
    },
  }
}
</script>
